import React from 'react'
import avatarImg from '../../images/practice/in-the-media-newspaper-image.png'

import './style.scss'

const listItemData = [
    {
        "ListText": "Klassen VS Wiers Estate/",
        "ListUrl" : "https://www.rslaw.com/2018/12/10/case-comment-klassen-v-wiers-estate/"
    }
]

const listItem = [
    'The master-builder of human happiness.',
    'Occasionally circumstances occur in which toil and pain',
    'Avoids pleasure itself, because it is pleasure',
    'who do not know how to pursue pleasure',
    'To take a trivial example, which of us ever undertakes',
]
const WillsandEstatesContentArea = ({ className, avatar, image }) => {
    return (
        <div className={className}>
            <div className="singleContentWrap">
                <h3>Wills &amp; Estates</h3>
                <h5></h5>
                <p>Estate planning can be a sensitive subject.  Whether it’s the family farm or your family heirlooms Adam can help you draft a will that ensures your wishes are respected.</p>
                <h5>Estate Administration &amp; Probate</h5>
                <p>The death of a loved one is a stressful time.  Seeing a lawyer about the necessary preparations to wind up their affairs shouldn’t be.  A lawyer can help you with the responsibilities of obtaining a grant of probate or administration and ensuring that every aspect of the estate is finalized.</p>
                <p>The fees for many of the ordinary legal services required to administer an estate are fixed and governed by a schedule in the Rules of the Court of Queen’s Bench.  Adam can meet with you and go through exactly what will be required and when, as well as what it will cost in a straightforward manner.</p>
                <h5>Powers of Attorney</h5>
                <p>A power of attorney can grant persons limited or unlimited authority to deal with your affairs.  If you have specific concerns or just want to make sure someone can step in on your behalf if something should happen, you should have a power of attorney prepared properly by a lawyer.  When you need it, is often too late to make one.</p>
                <h5>Living Will</h5>
                <p>In Saskatchewan, a ‘living will’ is called a health care directive.  Wishes for your medical treatment and instructions for your physicians in the event you can no longer communicate your wishes can be preserved in this document.  These require specific elements to satisfy the laws of Saskatchewan.</p>
                <p>Not everyone may feel they need a health care directive.  There are defaults under the laws of Saskatchewan for who gets to make these decisions, but they may not be your choice.  Contact Adam to learn more about health care directives as part of an estate plan.</p>
                <h5>Adult Guardianship</h5>
                <p>The Adult Guardianship and Co Decision Making Act in Saskatchewan governs situations where an adult may need a guardian or co-decision maker.  The superior court in Saskatchewan can exercise authority under this legislation to grant or deny persons the ability to intervene and assist with or decide the affairs of an adult who is in need of assistance.  The process can happen very quickly (as little as ten days), and if you are informed of an application or are the subject of an application, you should seek legal advice as soon as possible.</p>
                <p>The best interests of the adult are the paramount consideration, and they are presumed to have capacity until demonstrated otherwise.  The Court is to impose the least restrictive assistance necessary and preserve the rights of the adult, so far as they are able, to exercise their free will and participate/communicate in their own affairs.  The adult is entitled to representation wherever appropriate in an application under the act.</p>
                <p>There are strict requirements for an application for adult guardianship or co decision making and a lawyer can assist you with that.</p>
                <h5>Defending or Challenging a Will</h5>
                <p>If you disagree with the executor of an estate, or a beneficiary or third party obstructs the administration of a deceased’s affairs, there may need to be court proceedings to determine what should happen.  There are very strict timelines involved and it is important to have the correct advice and evidence put forward on your behalf.  Adam has experience both defending and challenging estates matters and can tell you what your options are.</p>
                {avatar && (
                    <div className="avatarWra">
                        <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>In The Media</h4>
                                <ul>
                                    {listItemData.map(list => (
                                        <li key={list.ListText}><a href={list.ListUrl}>{list.ListText}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default WillsandEstatesContentArea