import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import Service from '../../../components/Service'
import About from '../../../components/About'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
import BlogArea from '../../../components/BlogArea'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/about/2.jpg'
import breadcumb from '../../../images/breadcumb/about-us-people-background.jpg'

import './style.scss'

const aboutText = [
    { text: 'Mr. Adam Fritzler grew up on a farm in rural Saskatchewan.  He studied at the College of Agriculture at the University of Saskatchewan and received his B. Sc. in 2007. In 2010 he convocated from the College of Law at the University of Saskatchewan.' },
    { text: 'Adam joined a small Regina firm in June of 2010 as an articling student and signed the Rolls in June of 2011. He practices primarily in criminal, civil, and family areas of litigation and has appeared before Provincial Court, the Court of Queen’s Bench and the Court of Appeal in Saskatchewan.' },
    { text: 'When away from practice, he enjoys the outdoors and is an avid sportsman and conservationist.' },
]

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Family Law',
        content: 'Family law matters can be complicated and legal advice is always recommended.',
        item1: 'Custody and Access',
        item2: 'Child Support',
        item3: 'Spousal Support',
        item4: 'Property Division',

    },
    {
        icon: 'flaticon-wounded',
        title: 'Personal Injury',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-employee',
        title: 'Business Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-thief',
        title: 'Criminal Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-university-graduate-hat',
        title: 'Education Law',
        content: 'There are many variations of passages of Lorem '
    },
    {
        icon: 'flaticon-house',
        title: 'Real Estate Law',
        content: 'There are many variations of passages of Lorem '
    },
]

const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'About us'}
]

const AboutPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="About Us"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <Service className="serviceArea mt-0" />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="Why You Need Us"
                subTitle="We Are Expert"
                images={about}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutText}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle="Areas Of Practice"
                services={services}
            />
            <Testmonial
                className="testmonialArea pt100"
            />
            <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            />

            <FooterArea/>
        </Fragment>
    )
}
export default AboutPage