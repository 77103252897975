import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
import FooterArea from '../../../components/FooterArea'
import ContactArea from '../../../components/ContactArea'

import breadcumb from '../../../images/breadcumb/law-books-background.jpg'
import team from '../../../images/Attorneys-single/adam-fritzler-small-profile.jpg'
import portfolio1 from '../../../images/studies/1.jpg'
import portfolio2 from '../../../images/studies/2.jpg'
import portfolio3 from '../../../images/studies/3.jpg'

import criminalPdf from '../../../dox/2018 overview of the adult criminal justice system.pdf'

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'About', route: '/about' },
    { name: 'Adam Fritzler' }
]
const teamMembarInfo = [
    { level: 'Positon: ', text: 'Lawyer' },
    { level: 'Practice Area: ', text: 'Family Law, Criminal Defence, Real Estate, Wills & Estates' },
    { level: 'Experience: ', text: '10 Years' },
    { level: 'Address: ', text: '102-2400 College Ave. Regina, SK S4P 1C8' },
    { level: 'Phone: ', text: '306.787.1141' },
    { level: 'Toll Free: ', text: '1.877.424.1906' },
    { level: 'Email: ', text: 'adam@fritzlerlaw.ca' },
    { level: 'Fax: ', text: '306.787.2316' },
]

const SingleTeamPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Adam Fritzler"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <div className='singleTeamArea'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1 col-12">
                            <div className="row">
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamImgWrap">
                                        <div className="singleTeamImg">
                                            <img src={team} alt="" />
                                        </div>
                                        <h4>Adam Fritzler</h4>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="singleTeamContent">
                                        <h4>Information</h4>
                                        <ul className="teamMembarInfo">
                                            {teamMembarInfo.map((teamInfo, i) => (
                                                <li key={i}>
                                                    <span>{teamInfo.level}</span>
                                                    {teamInfo.text}
                                                </li>
                                            ))}
                                        </ul>
                                        {/*
                                        <ul className="socialShare">
                                            <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                            <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                            <li><a href="#"><i className="fa fa-linkedin"></i></a></li>
                                            <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                        </ul>
                                        */}
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="singleTeamInfo">
                                        <h4><strong>Please note, effective immediately I will not be taking on new clients.</strong></h4><p>I am currently employed as a staff lawyer with the Regina Rural office of Legal Aid Saskatchewan. From 2010 to 2020, I practiced in Regina representing clients on a wide variety of matters. Past clients can find more information here.</p>
                                        <p>Beginning in 2020, I have directed my practice to provide professional services to eligible persons dealing with criminal and family law issues through Legal Aid Saskatchewan.</p>
                                        <h3>Personal Experience</h3>
                                        <p>Mr. Adam Fritzler grew up on a farm in rural Saskatchewan.  He studied at the College of Agriculture at the University of Saskatchewan and received his B. Sc. in 2007. In 2010 he convocated from the College of Law at the University of Saskatchewan.</p>
                                        <p>Adam joined a small Regina firm in June of 2010 as an articling student and signed the Rolls in June of 2011. He practices primarily in criminal, civil, and family areas of litigation and has appeared before Provincial Court, the Court of Queen’s Bench and the Court of Appeal in Saskatchewan.</p>
                                        <p>When away from practice, he enjoys the outdoors and is an avid sportsman and conservationist.</p>
                                        <h3>Criminal Law</h3>
                                        <p>Adam has represented clients in criminal proceedings at all levels of Court in Saskatchewan. My experience includes a wide range of 
                                        summary and indictable offences including impaired driving, property theft and mischief, drug possession and trafficking, assaults, 
                                        domestic violence, breaches of conditions, weapons, the Firearms Act, sexual offences and property forfeitures.
                                        </p>
                                        <p>You can find an outline of the criminal law process <a target="_blank" href ={criminalPdf}>here</a></p>
                                            
                                        <h4>Criminal news and professional involvement:</h4>
                                        <ul>
                                            <li><a href="https://www.cbc.ca/news/canada/saskatchewan/sgi-collect-data-impaired-driving-study-1.5060418" target="_blank">SGI Impaired Driving Study</a></li>
                                            <li><a href="https://www.thelawyersdaily.ca/articles/6115" target="_blank">Lawyer’s Daily re: R. v. Knoblauch</a></li>
                                            <li><a href="https://www.pressreader.com/canada/regina-leader-post/20140913/281638188407008">Regina Leader-Post: 2014-09-13 - Sentence on Assault Conviction Reduced - PressReader</a></li>
                                        </ul>
                                         <br></br>

                                        <h4>STLA member, editor of Saskatchewan Advocate</h4>
                                        <hr></hr>
                                        <h3>Family Law</h3>
                                        <p>Since 2010 Adam has been assisting clients with issues related to custody, access, and support.  These matters can be resolved through negotiation, 
                                        through the court process, or a combination of the two.  In the negotiation process, you control the outcome and create the solutions for you 
                                        and your family.  In the court process, a judge of the court will impose the solution after hearing the relevant evidence and argument from lawyers.  
                                        At any stage of the court process, you can choose to agree instead of continue through the courts.</p>
                                        <p>Adam has conducted negotiations on behalf of clients, and when necessary and appropriate, successfully represented clients at all stages 
                                        of the family law court process in Saskatchewan from the initial application stage to the Court of Appeal.</p>
                                        <hr></hr>
                                        <h3>Past Clients</h3>
                                        <p>Adam retains wills, powers of attorney and other estate planning documents that he has prepared for clients during his time in private practice.  
                                            Adam's active civil, corporate and other files have been redistributed pursuant to any instructions that he was given at the time or remain with 
                                            Sharon Martin of Bertram Scrivens Prior Martin.  If you have any questions about estate planning documents or to discuss your matter please 
                                            feel free to email Adam .</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Portfolio
                title="Participated Case"
                subTitle="Here Our Best Work"
                fullWidth={true}
                portfolioItem={portfolioItem}
                className="portfolioArea portfolioAreaStyleFour"
            /> */}
            <ContactArea
                className="contactArea"
            />

            <FooterArea />
        </Fragment>
    )
}
export default SingleTeamPage