import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../../images/logo/adamfritzlerlaw-logo.png'
import './style.scss'
import ScrollToTop from '../../ScrollToTop.js'

const footerLinks = [
    {
        title: 'Contact Us', menus: [
            { name: 'Adam Fritzler'},
            { name: ' 102-2400 College Ave,', },
            { name: 'Regina, SK', },
            { name: 'S4P 1C8', },
            { name: 'Phone:  1-306-787-1141', },
            { name: 'Toll free: 1-877-424-1906', },
            { name: 'Toll free: 1-306-787-2316', },
            { name: 'Email: adam@fritzlerlaw.ca', },
        ]
    },
    {
        title: 'Quick Links', menus: [
            { name: 'Home', route: '/' },
            { name: 'About Us', route: 'attorneys-single' },
            { name: 'Contact Us', route: 'contact' },
        ]
    },
    
]

const FooterArea = () => {
    return (
        <footer className="footerArea">
            <div className="footerTopArea">
                <div className="container">
                    <div className="row">
                        
                        {footerLinks.map((menu, i) => (
                            <div className="col-md-4">
                                <div className="footerWrap">
                                    <h3>{menu.title}</h3>
                                    <hr></hr>
                                    <ul>
                                        {menu.menus.map((item, i) => (
                                            <li key={i}>{item.route ? <Link to={`/${item.route}`}>{item.name}</Link> : `${item.name}`}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                        <div className="col-lg-4 col-md-6 col-sm-6">
                        <ScrollToTop />
                            <div className="footerLogo">
                                <Link to="/">
                                    <img src={logo} alt="" />
                                </Link>
                                <h4>Get In Touch With Us</h4>
                                <p>If you have legal issues or need for legal advice, we’re available to chat. Be they family related, real estate related, or criminal related, Adam Fritzler can assist. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footerBottomArea">
                <div className="container">
                    <div className="footerBottomContent">
                        <div className="row">
                            <div className="col-md-8 col-sm-10 col-12">
                                <span>Privacy Policy | © 2019 Fritzler Law Professional Corporation. All rights reserved</span>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default FooterArea