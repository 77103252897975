import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

import blog1 from '../../images/blog-page/marijuana-buds.jpg'
import blog2 from '../../images/blog-page/2.jpg'
import blog3 from '../../images/blog-page/beerbottle-keys.jpg'
import blog4 from '../../images/blog-page/4.jpg'
import blog5 from '../../images/blog-page/5.jpg'
import avatar from '../../images/blog-page/news-icon.png'
const blogPost = [
    {
        image: blog3,
        title: 'SGI looks to collect data about impaired driving through study',
        text: 'Drivers who participate would willingly submit breathalyzer and oral fluid samples. SGI is looking for a company to conduct a study that will help the insurance agency better understand impaired driving on Saskatchewan roads. The Crown corporation currently relies on data collected from police, the coroner\'s office and the semi-annual traffic safety monitor survey.',
        linksource: 'https://www.cbc.ca/news/canada/saskatchewan/sgi-collect-data-impaired-driving-study-1.5060418',
        blogdetailslink: 'newsarticle/1',
        meta: [
            { avatar: avatar, name: 'Bryan Eneas - CBC News', level: 'Criminal Law', date: 'Mar 18, 2019 ' }
        ]
    },
    {
        title: '2018 Saskatchewan decision in Klassen v. Estate of John Arnold Wiers',
        text: 'Klassen was a decision which turned on a careful assembling of the evidence. While there was no single piece of magic evidence, Klassen reminds parties how methodical gathering of clues as to a testator’s intent, can ultimately successfully rebut a challenge.',
        linksource: 'https://www.rslaw.com/2018/12/10/case-comment-klassen-v-wiers-estate/',
        blogdetailslink: 'newsarticle/2',
        meta: [
            { avatar: avatar, name: 'By James D Steele - rslaw.com', level: 'Family Law', date: 'Dec 10, 2018' }
        ]
    },
    {
        image: blog1,
        title: 'Canadian child protection agencies say they won\t treat weed differently now that it’s legal',
        text: 'Child protection authorities across Canada say they won’t change the way they evaluate parents who smoke weed, regardless of the plant’s legal status. Responding to questions from VICE News, authorities across the country were unanimous: They only evaluate a parent’s capacity to care for their child, regardless of what substances the child’s caregivers may be using. While a parent’s substance use is a factor in that evaluation, authorities said, they do not differentiate between the use of illegal or legal substances.',
        linksource: 'https://www.vice.com/en_ca/article/yw7vg5/canadian-child-protection-agencies-say-they-wont-treat-weed-differently-now-that-its-legal',
        blogdetailslink: 'newsarticle/3',
        meta: [
            { avatar: avatar, name: 'Nathan Munn - vice.com', level: 'Criminal Law', date: 'Nov 14,2018' }
        ]
    },
    {        
        title: 'Appeal Court reconvicts impaired driver, finds police dutifully told him of rights',
        text: 'A driver caught with glassy eyes, a teetering step and the smell of alcohol has had his conviction restored after Saskatchewan’s Appeal Court found that the arresting officer did not have...',
        linksource: 'https://www.thelawyersdaily.ca/articles/6115',
        blogdetailslink: 'newsarticle/4',
        meta: [
            { avatar: avatar, name: 'By Terry Davidson - thelawyersdaily.ca', level: 'Criminal Law', date: 'Friday, March 16, 2018' }
        ]
    },
    
    {
        title: 'Sentence on assault conviction reduced',
        text: 'After receiving on of the highest sentences in Canada for an aggravated sexual assault, a 26-year-old man has caught a break from the Saskatchewan Court of Appeal.',
        linksource: 'https://www.pressreader.com/canada/regina-leader-post/20140913/281638188407008',
        blogdetailslink: 'newsarticle/5',
        meta: [
            { avatar: avatar, name: 'By Barb Pacholik - Leader Post', level: 'Family Law', date: 'Sep 13, 2014' }
        ]
    },
    
]
const BlogPost = () => {
    return (
        <Fragment>
            {blogPost.map((blog, i) => (
                <div key={i} className="blogPostWrapper">
                    <div className="blogPostImg">
                        <img src={blog.image} alt="" />
                    </div>
                    <div className="blogPostContent">
                        <ul className="blogPostMeta">
                            {blog.meta.map((meta, i) => (
                                <Fragment key={i}>
                                    <li><img src={meta.avatar} alt=""/></li>
                                    <li>{meta.name}</li>
                                    <li>{meta.level}</li>
                                    <li>{meta.date}</li>
                                </Fragment>
                            ))}
                        </ul>
                        <h3><Link to='newsarticle'>{blog.title}</Link></h3>
                        <p>{blog.text}</p>
                        <Link className="readmore" to={blog.blogdetailslink}>Read more...</Link> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a class="readmore" href={blog.linksource}>Source Article Link</a>
                    </div>
                    <hr></hr>
                </div>
            ))}
        </Fragment>
    )
}
export default BlogPost