import React from 'react'
import { Link } from 'react-router-dom'
import './style.scss'

const categoryMenu = [
    { name: 'Family Law', route: "family-law" },
    { name: 'Criminal Law', route: "criminal-law" },
    { name: 'Wills & Estates', route: "willsandestates-law" },
    { name: 'Real Estate', route: "realestate-law" },
]

const CategorySidebar = ({ className, title }) => {
    return (
        <div className={className}>
            <h3 className="sidebarTitle">{title}</h3>
            <ul>
                {categoryMenu.map((categoryMenu,i) => (
                    <li key={i}><Link to={categoryMenu.route}>{categoryMenu.name}</Link></li>
                ))}
            </ul>
        </div>
    )
}
export default CategorySidebar