import React from 'react'
import avatarImg from '../../images/practice/4.jpg'

import './style.scss'

const listItem = [
    'The master-builder of human happiness.',
    'Occasionally circumstances occur in which toil and pain',
    'Avoids pleasure itself, because it is pleasure',
    'who do not know how to pursue pleasure',
    'To take a trivial example, which of us ever undertakes',
]
const RealEstateLawContentArea = ({ className, avatar, image }) => {
    return (
        <div className={className}>

            <div className="singleContentWrap">
                <h3>Real Estate Law</h3>
                <h5>I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born</h5>
                <p>Adam has the experience and support in place to help you with:</p>
                <ul>
                    <li>Residential real estate – buying or selling a home</li>
                    <li>Commercial real estate – buying, selling, and leasing property for your business or as an investment</li>
                    <li>Agricultural real estate – farm land leasing, management, purchase and sale, estate and succession planning, agreements and negotiation</li>
                </ul>
                {avatar && (
                    <div className="avatarWra">
                        <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                                <h4>Family Law Organizations</h4>
                                <ul>
                                    {listItem.map(list => (
                                        <li key={list}>{list}</li>
                                    ))}

                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default RealEstateLawContentArea