import React from 'react'
import SectionTitle from '../Title'
import { Link } from 'react-router-dom'
import './style.scss'

// images
import blog1 from '../../images/blog/beerbottle-keys.jpg'
import blog2 from '../../images/blog/blue-ballpoint-pen-antique-pocket-watch-two-brass-keys-and-a-last-will-and-testament-on-a-vinyl-desk-pad.jpg'
import blog3 from '../../images/blog/marijuana-buds.jpg'
import avatar from '../../images/blog-page/news-icon.png'

const blogs = [
    {
        images: blog1, title: 'SGI looks to collect data about impaired driving through study', meta: {
            avatar: avatar, name: 'By Bryan Eneas - CBC News', time: 'Mar 18, 2019'
        }
    },
    {
        images: blog2, title: '2018 Saskatchewan decision in Klassen v. Estate of John Arnold Wiers', meta: {
            avatar: avatar, name: 'By James D Steele - rslaw.com', time: 'Dec 10, 2018'
        }
    },
    {
        images: blog3, title: 'Canadian child protection agencies say they won\'t treat weed differently now that it’s legal', meta: {
            avatar: avatar, name: 'Nathan Munn - vice.com', time: 'Nov 14,2018'
        }
    },
]


const BlogArea = ({ className, title, subTitle }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
                    {blogs.map((blog, i) => (
                        <div key={i} className="col-lg-4 col-sm-6 col-12" >
                            <div className="blogWrap">
                                <div className="blogImage">
                                    <img src={blog.images} alt="" />
                                </div>
                                <div className="blogContent">
                                    <h3><Link to="/blog-fullwidth">{blog.title}</Link></h3>
                                    <ul className="blogMeta">
                                        <li><img src={blog.meta.avatar} alt="" /></li>
                                        <li>{blog.meta.name}</li>
                                        <li>{blog.meta.time}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default BlogArea