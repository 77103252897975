import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import About from '../../../components/About'
import ContactArea from '../../../components/ContactArea'
import FooterArea from '../../../components/FooterArea'
// images
import about from '../../../images/about/adam-fritzler-home-page-picture.jpg'
import signature from '../../../images/about/1.png'

const aboutText = [
    { text: 'I am currently employed as a staff lawyer with the Regina Rural office of Legal Aid Saskatchewan.  From 2010 to 2020, I practiced in Regina representing clients on a wide variety of matters.  Past clients can find more information here.' },
    { text: 'Beginning in 2020, I have directed my practice to provide professional services to eligible persons dealing with criminal and family law issues through Legal Aid Saskatchewan.' },
]


const HomePageOne = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea" />
            </header>
            
            
            <About
                className="aboutArea"
                title="About Adam Fritzler"
                images={about}
                signature={signature}
                pragraphs={aboutText}
            />
            
            />
            <ContactArea
                className="contactArea"
            />

            <FooterArea/>
        </Fragment>
    )
}
export default HomePageOne