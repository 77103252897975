import React from 'react'
import avatarImg from '../../images/practice/in-the-media-newspaper-image.png'
import criminalPdf from '../../dox/2018 overview of the adult criminal justice system.pdf'

import './style.scss'

const listItemData = [
    {
        "ListText": "Vice News re: marijuana",
        "ListUrl" : "https://www.vice.com/en_ca/article/yw7vg5/canadian-child-protection-agencies-say-they-wont-treat-weed-differently-now-that-its-legal"
    },
    {
        "ListText": "SGI Impaired Driving Study",
        "ListUrl" : "https://www.cbc.ca/news/canada/saskatchewan/sgi-collect-data-impaired-driving-study-1.5060418"
    },
    {
        "ListText": "Lawyer’s Daily re: R. v. Knoblauch",
        "ListUrl" : "https://www.thelawyersdaily.ca/articles/6115"
    },
    {
        "ListText": "Regina Leader-Post: 2014-09-13 - Sentence on Assault Conviction Reduced - PressReader",
        "ListUrl" : "https://www.pressreader.com/canada/regina-leader-post/20140913/281638188407008"
    }    
]

const listItem = [
    'The master-builder of human happiness.',
    'Occasionally circumstances occur in which toil and pain',
    'Avoids pleasure itself, because it is pleasure',
    'who do not know how to pursue pleasure',
    'To take a trivial example, which of us ever undertakes',
]
const CriminalLawContentArea = ({ className, avatar, image }) => {
    return (
        <div className={className}>

            <div className="singleContentWrap">
                <h3>Criminal Law</h3>
                
                <p>Criminal law in Canada has become a complex and comprehensive field.  Experienced counsel is critical. Cases often require immediate action to begin the defense process in an effort to preserve evidence, establish defenses or even obtain a publication ban.  Adam Fritzler has spent years successfully representing clients in criminal matters from simple assaults, impaired driving, drug possession and trafficking, drug impaired motor vehicle accidents to firearms offences and murders.  </p>
                <h5>Useful Information</h5>
                <p>Prosecutions may be summary proceedings, meaning that the process will take place in the Provincial Court and for which sentences are generally shorter upon conviction 
                    or indictable offences for which the maximum can be life imprisonment.  For indictable offences an accused person can choose to have a trial in provincial court, or in 
                    Queen's Bench (Superior Court) before a judge alone or before a judge and jury.  Adam can help you with that strategic decision. -Navigating the Adult Criminal Justice System PDF</p>
                <h5>Before you plead guilty:</h5>
                <p>The Charter of Rights and Freedoms protects an individual from unlawful intrusions by the police.  Charter law is complex and requires thorough analysis when advancing a case.  Finding a breach is just the first step; how to advance a coherent argument with the necessary evidentiary foundation at trial requires careful and thorough work by a lawyer to ensure the best chance of success. Sentences are becoming higher and higher for most offences.  Many offences now have minimum mandatory sentences which means that a judge may have no choice but to send a person to jail.  Other offences have ancillary orders like weapons prohibitions or participation in sex offender registries.</p>
                <p>For an in depth overview of the adult criminal justice system process in Canada, <a href ={criminalPdf}>download and view this PDF</a>.</p>
                <hr></hr>
                {avatar && (
                    <div className="avatarWra">
                        <div className="row">
                            <div className="col-md-4">
                                <img src={avatarImg} alt="" />
                            </div>
                            <div className="col-md-8">
                            <h4>In The Media</h4>
                                <ul>
                                    {listItemData.map(list => (
                                        <li key={list.ListText}><a href={list.ListUrl}>{list.ListText}</a></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
export default CriminalLawContentArea